module.exports = [{
      plugin: require('C:/workspace/my/pre-drinks-gatsby/pre-drinks/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/workspace/my/pre-drinks-gatsby/pre-drinks/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Carter One","Raleway"]}},
    },{
      plugin: require('C:/workspace/my/pre-drinks-gatsby/pre-drinks/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
