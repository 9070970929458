// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-drinking-games-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames.tsx" /* webpackChunkName: "component---src-pages-drinking-games-tsx" */),
  "component---src-pages-drinking-games-animals-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Animals.tsx" /* webpackChunkName: "component---src-pages-drinking-games-animals-tsx" */),
  "component---src-pages-drinking-games-battleshots-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Battleshots.tsx" /* webpackChunkName: "component---src-pages-drinking-games-battleshots-tsx" */),
  "component---src-pages-drinking-games-beer-pong-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\BeerPong.tsx" /* webpackChunkName: "component---src-pages-drinking-games-beer-pong-tsx" */),
  "component---src-pages-drinking-games-boat-race-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\BoatRace.tsx" /* webpackChunkName: "component---src-pages-drinking-games-boat-race-tsx" */),
  "component---src-pages-drinking-games-card-drinking-games-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\CardDrinkingGames.tsx" /* webpackChunkName: "component---src-pages-drinking-games-card-drinking-games-tsx" */),
  "component---src-pages-drinking-games-card-head-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\CardHead.tsx" /* webpackChunkName: "component---src-pages-drinking-games-card-head-tsx" */),
  "component---src-pages-drinking-games-centurion-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Centurion.tsx" /* webpackChunkName: "component---src-pages-drinking-games-centurion-tsx" */),
  "component---src-pages-drinking-games-chase-the-ace-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\ChaseTheAce.tsx" /* webpackChunkName: "component---src-pages-drinking-games-chase-the-ace-tsx" */),
  "component---src-pages-drinking-games-cheat-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Cheat.tsx" /* webpackChunkName: "component---src-pages-drinking-games-cheat-tsx" */),
  "component---src-pages-drinking-games-cross-the-bridge-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\CrossTheBridge.tsx" /* webpackChunkName: "component---src-pages-drinking-games-cross-the-bridge-tsx" */),
  "component---src-pages-drinking-games-drinking-snap-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\DrinkingSnap.tsx" /* webpackChunkName: "component---src-pages-drinking-games-drinking-snap-tsx" */),
  "component---src-pages-drinking-games-firing-line-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\FiringLine.tsx" /* webpackChunkName: "component---src-pages-drinking-games-firing-line-tsx" */),
  "component---src-pages-drinking-games-fives-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Fives.tsx" /* webpackChunkName: "component---src-pages-drinking-games-fives-tsx" */),
  "component---src-pages-drinking-games-fuck-the-dealer-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\FuckTheDealer.tsx" /* webpackChunkName: "component---src-pages-drinking-games-fuck-the-dealer-tsx" */),
  "component---src-pages-drinking-games-fuzzy-duck-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\FuzzyDuck.tsx" /* webpackChunkName: "component---src-pages-drinking-games-fuzzy-duck-tsx" */),
  "component---src-pages-drinking-games-higher-or-lower-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\HigherOrLower.tsx" /* webpackChunkName: "component---src-pages-drinking-games-higher-or-lower-tsx" */),
  "component---src-pages-drinking-games-horse-racing-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\HorseRacing.tsx" /* webpackChunkName: "component---src-pages-drinking-games-horse-racing-tsx" */),
  "component---src-pages-drinking-games-invisible-ball-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\InvisibleBall.tsx" /* webpackChunkName: "component---src-pages-drinking-games-invisible-ball-tsx" */),
  "component---src-pages-drinking-games-never-have-i-ever-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\NeverHaveIEver.tsx" /* webpackChunkName: "component---src-pages-drinking-games-never-have-i-ever-tsx" */),
  "component---src-pages-drinking-games-other-drinking-games-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\OtherDrinkingGames.tsx" /* webpackChunkName: "component---src-pages-drinking-games-other-drinking-games-tsx" */),
  "component---src-pages-drinking-games-pyramid-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Pyramid.tsx" /* webpackChunkName: "component---src-pages-drinking-games-pyramid-tsx" */),
  "component---src-pages-drinking-games-ride-the-bus-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\RideTheBus.tsx" /* webpackChunkName: "component---src-pages-drinking-games-ride-the-bus-tsx" */),
  "component---src-pages-drinking-games-ring-of-fire-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\RingOfFire.tsx" /* webpackChunkName: "component---src-pages-drinking-games-ring-of-fire-tsx" */),
  "component---src-pages-drinking-games-sentence-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\Sentence.tsx" /* webpackChunkName: "component---src-pages-drinking-games-sentence-tsx" */),
  "component---src-pages-drinking-games-slap-clap-click-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\SlapClapClick.tsx" /* webpackChunkName: "component---src-pages-drinking-games-slap-clap-click-tsx" */),
  "component---src-pages-drinking-games-the-alphabet-game-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheAlphabetGame.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-alphabet-game-tsx" */),
  "component---src-pages-drinking-games-the-celebrity-game-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheCelebrityGame.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-celebrity-game-tsx" */),
  "component---src-pages-drinking-games-the-dealer-and-the-fuzz-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheDealerAndTheFuzz.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-dealer-and-the-fuzz-tsx" */),
  "component---src-pages-drinking-games-the-dictator-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheDictator.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-dictator-tsx" */),
  "component---src-pages-drinking-games-the-forehead-game-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheForeheadGame.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-forehead-game-tsx" */),
  "component---src-pages-drinking-games-the-wotlewot-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TheWotlewot.tsx" /* webpackChunkName: "component---src-pages-drinking-games-the-wotlewot-tsx" */),
  "component---src-pages-drinking-games-tv-taches-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TvTaches.tsx" /* webpackChunkName: "component---src-pages-drinking-games-tv-taches-tsx" */),
  "component---src-pages-drinking-games-twenty-one-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\TwentyOne.tsx" /* webpackChunkName: "component---src-pages-drinking-games-twenty-one-tsx" */),
  "component---src-pages-drinking-games-what-the-fuck-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\WhatTheFuck.tsx" /* webpackChunkName: "component---src-pages-drinking-games-what-the-fuck-tsx" */),
  "component---src-pages-drinking-games-word-drinking-games-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\WordDrinkingGames.tsx" /* webpackChunkName: "component---src-pages-drinking-games-word-drinking-games-tsx" */),
  "component---src-pages-drinking-games-would-you-rather-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\DrinkingGames\\WouldYouRather.tsx" /* webpackChunkName: "component---src-pages-drinking-games-would-you-rather-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\workspace\\my\\pre-drinks-gatsby\\pre-drinks\\.cache\\data.json")

